import writeXlsxFile from "write-excel-file";
import { getConfigurations } from "../../../utils/services/ranking";

export async function createXlsx({
  jwt,
  eventId,
  limit,
  totalItems,
  setFinishExport,
}) {
  const formatDate = (date, time) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("-");
    return `${formatedDate} - ${time}`;
  };

  const schema = [
    {
      column: "ID da Configuração",
      type: String,
      value: (config) => config.id,
    },
    {
      column: "Código",
      type: String,
      value: (config) => config.code,
    },
    {
      column: "Icone",
      type: String,
      value: (config) => config.urlIcon,
    },
    {
      column: "Pontuação",
      type: Number,
      value: (config) => config.earnedPoints,
    },
    {
      column: "Descrição",
      type: String,
      value: (config) => config.description,
    },
    {
      column: "Diário",
      type: String,
      value: (config) => (config.daily ? "Sim" : "Não"),
    },
    {
      column: "Data Inicio",
      type: String,
      value: (config) =>
        config.startAt ? formatDate(config.startAt, config.startHour) : "",
    },
    {
      column: "Data Fim",
      type: String,
      value: (config) =>
        config.endAt ? formatDate(config.endAt, config.endHour) : "",
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getConfigurations({
      id: eventId,
      jwt: jwt,
      offset: i,
      limit,
      search: "",
      columnSearch: "code",
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.map((e) => e.data).flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "download-configurações.xlsx",
    });
  }
}
