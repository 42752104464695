import React, { useState, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardText,
  Row,
  Col,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { formatBytes } from "../../../utils/helpers/files/formatBytes";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// moment
import moment from "moment/moment";
// xlsx
import ModeloUpload from "../../../assets/statics/modelo_importação_configGamification.xlsx";
import readXlsxFile from "read-excel-file";
import {
  allExcelColumns,
  findRowsWithError,
  excelColumnsPtBr,
  findDuplicateName,
  findExistingCodes,
} from "./importValidationConfig";
// componentes
import ModalExcelRowsConfig from "./ModalExcelRowsConfig";
//service
import { createConfiguration } from "../../../utils/services/ranking";

export default function ModalImportConfig(props) {
  const { isOpen, toggle, idEvent, token, getAllConfigurations } = props;
  // documento
  const [selectedFiles, setselectedFiles] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);
  // botões
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  // importar
  const importActivated = useRef(false);
  // erros
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState({});
  // alerta
  const [success, setSuccess] = useState(false);

  function cancelImport() {
    importActivated.current = false;
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
  }

  async function uploadService() {
    try {
      importActivated.current = true;
      setBtnSend(false);
      setBtnCancel(true);

      const schema = {
        Codigo: { prop: "code", type: String },
        Pontuacao: { prop: "point", type: String },
        Icone: { prop: "icon", type: String },
        Descricao: { prop: "description", type: String },
        Diario: { prop: "daily", type: String },
        DataInicio: { prop: "dateStart", type: Date },
        DataFim: { prop: "dateEnd", type: Date },
      };

      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

      const readXlsx = async () => {
        try {
          if (!selectedFiles[0]) {
            setErrors({
              type: "text",
              error: "Nenhum arquivo selecionado",
            });
            setModalErrors(true);
            return;
          }

          const { rows } = await readXlsxFile(selectedFiles[0], { schema });

          if (
            !rows ||
            !Array.isArray(rows) ||
            (Array.isArray(rows) && rows.length === 0)
          ) {
            setErrors({
              type: "text",
              error: "Erro ao ler as linhas do arquivo Excel ou arquivo vazio",
            });
            setModalErrors(true);
            return;
          }

          const validateRows = rows.map((row, i) => allExcelColumns(row, i));

          const rowsWithError = await findRowsWithError(validateRows);

          if (rowsWithError && rowsWithError.length > 0) {
            setErrors({
              type: "required",
              data: rowsWithError,
            });
            setModalErrors(true);
            return;
          }

          const duplicateName = await findDuplicateName(validateRows);

          if (duplicateName && duplicateName.length > 0) {
            setErrors({
              type: "duplicate",
              data: duplicateName,
            });
            setModalErrors(true);
            return;
          }

          const existingCodes = await findExistingCodes(
            validateRows,
            idEvent,
            token
          );

          if (existingCodes && existingCodes.length > 0) {
            setErrors({
              type: "existingCode",
              data: existingCodes,
            });
            setModalErrors(true);
            return;
          }

          setTotalRows(validateRows.length);

          validateRows.forEach(async (row, i) => {
            await sleep(100);
            await submitToApi(row, i, validateRows.length);
          });
        } catch (error) {
          console.log("erro", error);
        }
      };

      readXlsx();
    } catch (error) {
      console.log("erro 2", error);
    }
  }

  async function submitToApi(row, index, totalRows) {
    const daily = row.daily.toUpperCase() === "SIM" ? true : false;
    var startDate = null;
    var startHour = null;
    var endDate = null;
    var endHour = null;

    if (daily) {
      startDate = moment.utc(new Date(row.dateStart)).format().split("T")[0];
      startHour = moment.utc(new Date(row.dateStart)).format("H:mm");
      endDate = moment.utc(new Date(row.dateEnd)).format().split("T")[0];
      endHour = moment.utc(new Date(row.dateEnd)).format("H:mm");
    }

    await createConfiguration({
      code: row.code,
      urlIcon: row.icon,
      daily: daily,
      startAt: startDate,
      endAt: endDate,
      startHour: startHour,
      endHour: endHour,
      earnedPoints: row.point,
      description: row.description ? row.description : "",
      idEvent: idEvent,
      jwt: token,
    }).then((res) => {
      if (res.message === "CONFIGURATION_CREATED") {
        setTotalSended(totalSended + 1);
      }

      if (index + 1 === totalRows) {
        setSuccess(true);
      }
    });
  }

  return (
    <>
      {modalErrors && (
        <ModalExcelRowsConfig
          toggle={() => setModalErrors(false)}
          rows={errors}
          columnNames={excelColumnsPtBr}
        />
      )}

      {success ? (
        <SweetAlert
          success
          title={"Upload"}
          onConfirm={() => {
            getAllConfigurations();
            toggle();
            setSuccess(false);
          }}
        >
          {"O arquivo foi enviado com sucesso!"}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Importar Configurações</ModalHeader>
        <ModalBody>
          <Card className="p-1">
            <CardBody className="m-1 p-2">
              <CardText className="mb-2 p-10">
                O arquivo a ser importado deve seguir este{" "}
                <a
                  href={ModeloUpload}
                  download="modelo_importação_configGamification.xlsx"
                >
                  Modelo
                </a>
                , não modifique seu cabeçalho.
              </CardText>
            </CardBody>
          </Card>

          {selectedFiles.length === 0 && (
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-1"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-4">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                      <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          )}

          <div className="dropzone-previews mt-0" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-1">
                    <Row className="align-items-center">
                      <Col xs="11">
                        <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                      </Col>
                      <Col xs="1">
                        <span
                          style={{
                            fontSize: "16px",
                            color: "red",
                            fontWeight: "700",
                          }}
                          className="mdi mdi-close"
                          onClick={() => setselectedFiles([])}
                        />
                      </Col>
                    </Row>
                    {importActivated.current && (
                      <Row className="align-items-center">
                        <Col>
                          <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Card>
              );
            })}
          </div>

          <div className="text-center mt-3">
            <button
              type="button"
              disabled={!btnSend || selectedFiles.length === 0}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => uploadService()}
            >
              Enviar Configurações
            </button>
            <button
              type="button"
              className="btn btn-danger mx-1"
              disabled={!btnCancel}
              onClick={() => cancelImport()}
            >
              Cancelar
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
