import writeXlsxFile from "write-excel-file";
import { getFunctions } from "../../../utils/services/functions";

export async function createXlsx({
  jwt,
  eventId,
  limit,
  totalItems,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("-");
    return `${formatedDate}`;
  };

  const schema = [
    {
      column: "ID do QR Code",
      type: String,
      value: (qrCode) => qrCode.id,
    },
    {
      column: "Nome do QR Code",
      type: String,
      value: (qrCode) => qrCode.description,
    },
    {
      column: "Código da Pontuação",
      type: String,
      value: (qrCode) => qrCode.type,
    },
    {
      column: "Data da Criação",
      type: String,
      value: (qrCode) => (qrCode.createdAt ? formatDate(qrCode.createdAt) : ""),
    },
    {
      column: "Última Atualização",
      type: String,
      value: (qrCode) => (qrCode.updatedAt ? formatDate(qrCode.updatedAt) : ""),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getFunctions({
      limit,
      id: eventId,
      jwt,
      search: "",
      columnSearch: "name",
      offset: i,
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.functions)
    .flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "download-QRCode.xlsx",
    });
  }
}
