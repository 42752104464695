import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";

//service
import { createFunction } from "../../../utils/services/functions";
import { getConfigurations } from "../../../utils/services/ranking";
//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function ModalNewFunc(props) {
  const { isOpen, toggle, idEvent } = props;
  const [isLoading, setIsloading] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //state de erros
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [funcObjectError, setFuncObjectError] = useState(false);
  const [returnObjectError, setReturnObjectError] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  // configurações
  const [configurations, setConfigurations] = useState([]);
  // token
  const token = props.state.global_user_data.data.token;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (!e.target[0].value) {
      setNameError(true);
      return;
    } else setNameError(false);

    if (!e.target[1].value || e.target[1].value === "Selecione") {
      setTypeError(true);
      return;
    } else setTypeError(false);

    setIsloading(true);

    await createFunction({
      eventId: idEvent,
      description: e.target[0].value,
      functionObject: "--",
      responseObject: "--",
      type: e.target[1].value,
      jwt: token,
    })
      .then((response) => {
        setIsloading(false);
        if (response.message === "CREATED") {
          setsuccess_dlg(true);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  async function getAllConfigurations() {
    await getConfigurations({
      id: idEvent,
      jwt: token,
      offset: 1,
      limit: 1000,
      search: "",
      columnSearch: "code",
    })
      .then((res) => {
        if (res.count && res.count > 0) {
          setConfigurations(res.data);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getAllConfigurations();
  }, []);

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.getAllFunc();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Função criada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Adicionar novo QR Code</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col md={12}>
              <label className="form-label">Nome do QR Code*</label>
              <input className="form-control" type="text" />
              {nameError && (
                <p style={{ color: "red" }}>
                  O campo Nome do QR Code é obrigatório.
                </p>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={12}>
              <label className="form-label">Código da Pontuação*</label>
              <select className="form-control">
                <option value={"Selecione"}>Selecione</option>
                {configurations?.length > 0 &&
                  configurations.map((e, i) => (
                    <option key={i} value={e.code}>
                      {e.code}
                    </option>
                  ))}
              </select>
              {typeError && (
                <p style={{ color: "red" }}>
                  O campo Código da Pontuação é obrigatório.
                </p>
              )}
            </Col>
          </Row>

          <Row
            style={{
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalNewFunc);
