//service
import { getConfigurations } from "../../../utils/services/ranking";

export const excelColumnsPtBr = {
  Codigo: "code",
  Pontuacao: "point",
  Icone: "icon",
  Descricao: "description",
  Diario: "daily",
  DataInicio: "dateStart",
  DataFim: "dateEnd",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    code: row.code ? row.code : undefined,
    point: row.point ? row.point : undefined,
    icon: row.icon ? row.icon : undefined,
    description: row.description ? row.description : "",
    daily: row.daily ? row.daily : false,
    dateStart: row.dateStart ? row.dateStart : "",
    dateEnd: row.dateEnd ? row.dateEnd : "",
  };

  return formatedRow;
}

export async function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) =>
      row.code === undefined ||
      row.point === undefined ||
      row.icon === undefined ||
      (row.daily.toUpperCase() === "SIM" && !row.dateStart) ||
      (row.daily.toUpperCase() === "SIM" && !row.dateEnd)
  );

  if (rowsWithError.length > 0) {
    rowsWithError.map((e, i) => {
      rowsWithError[i] = {
        ...e,
        dateStart:
          e.daily.toUpperCase() === "SIM" && !e.dateStart
            ? undefined
            : e.dateStart,
        dateEnd:
          e.daily.toUpperCase() === "SIM" && !e.dateEnd ? undefined : e.dateEnd,
      };
    });
  }

  return rowsWithError;
}

export async function findDuplicateName(validateRows) {
  var duplicate = [];

  validateRows.map((row) => {
    var search = validateRows.filter((e) => e.code === row.code);

    if (search.length > 1) {
      duplicate.push({
        ...row,
        code: undefined,
      });
    }
  });

  return duplicate;
}

export async function findExistingCodes(validateRows, idEvent, token){
  // console.log(validateRows)

  var allConfig = [];

  await getConfigurations({
    id: idEvent,
    jwt: token,
    offset: 1,
    limit: 1000,
    search: "",
    columnSearch: "code",
  }).then((res) => {
    allConfig = res.data;
  });

  var existingCodes = []

  validateRows.map((row) => {
    // console.log(row)

    var search = allConfig.find((e) => e.code === row.code);

    if (search) {
      existingCodes.push(row);
    }
  });

  return existingCodes
}
