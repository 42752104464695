import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SearchFilters from "../../../../components/SearchFilters";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "../../../../components/Paginationv3";
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import WaitExport from "../../../../components/WaitExport";

import { useLocation } from "react-router-dom";
import { ModalNewCP } from "./modals/ModalNewCP";
import {
  deleteCp,
  fetchCps,
  fetchCpsReports,
} from "../../../../utils/services/portalBoticario";
import { ModalEditCP } from "./modals/ModalEditCP";
import { createXlsxCP } from "./xlsx/createCP";

const QuotasPerCP = (props) => {
  const location = useLocation();

  const token = props.state.global_user_data.data.token;
  const idEvent = location.state.idEvent;

  const [listCp, setListCp] = useState([]);
  const [cpEdit, setCpEdit] = useState([]);
  const [cpReport, setCpReport] = useState([]);

  const [newCP, setNewCP] = useState(false);
  const [isOpenEditCp, setIsOpenEditCp] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const columnsFilter = [
    { name: "Nome", value: "name", type: "text" },
    { name: "Código", value: "code", type: "text" },
  ];

  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getCps = async () => {
    try {
      const data = await fetchCps(
        token,
        currentIndex > 0 ? currentIndex : 1,
        itemsPerPage,
        search,
        "",
        idEvent
      );
      setListCp(data);
    } catch (error) {
      console.error("Erro ao obter departamentos:", error);
    }
  };

  const getCpsReports = async () => {
    try {
      const data = await fetchCpsReports(token, idEvent);
      setCpReport(data);
    } catch (error) {
      console.error("Erro ao obter CPS REPORT: ", error);
    }
  };

  useEffect(() => {
    getCps();
  }, [search]);

  useEffect(() => {
    getCpsReports();
  }, [idEvent]);

  return (
    <>
      {newCP && (
        <ModalNewCP
          isOpen={newCP}
          toggle={() => setNewCP(false)}
          jwt={token}
          refetch={getCps}
          eventId={idEvent}
          refetchReport={getCpsReports}
        />
      )}

      {isOpenEditCp && (
        <ModalEditCP
          isOpen={isOpenEditCp}
          toggle={() => setIsOpenEditCp(false)}
          jwt={token}
          refetch={getCps}
          eventId={idEvent}
          cpEdit={cpEdit}
        />
      )}

      {isConfirmDelete && (
        <SweetAlert
          title="Deseja deletar esse CP?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await deleteCp(cpEdit.id, token);
              await getCps();
              await getCpsReports();
              setIsConfirmDelete(false);
              setIsShowSuccess(true);
            } catch (error) {
              console.error("Erro ao deletar o CP: ", error);
            }
          }}
          onCancel={() => setIsConfirmDelete(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}

      {isShowSuccess && (
        <SweetAlert
          title="Deletado"
          success
          onConfirm={() => setIsShowSuccess(false)}
        >
          CP deletado!
        </SweetAlert>
      )}

      <WaitExport
        isOpen={exportInProgress}
        name="CPs"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <div>
        <Card>
          <CardBody>
            <CardTitle>Cotas por CP</CardTitle>

            <div>
              <Collapse isOpen={collapseIsOpen}>
                <SearchFilters columns={columnsFilter} />
              </Collapse>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <form
                className="app-search d-none d-lg-block events-input-search"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type={search.includes("date") ? "date" : "text"}
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>

              <div className="mobile-btn-section" style={{ width: "100%" }}>
                <Row className="mobile-btns-top">
                  <form
                    className="app-search"
                    style={{ padding: "0px" }}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        value={search}
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Row>

                <SectionComponent
                  sectionName="Opções"
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />

                <Collapse isOpen={isOpen}>
                  {cpReport && cpReport.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxCP(
                            token,
                            100,
                            search,
                            countData,
                            setFinishExport,
                            idEvent
                          ) > setExportInProgress(true)
                        }
                      >
                        Exportar CPs
                      </div>
                    </Row>
                  )}

                  <Row className="mobile-btns-top">
                    <div onClick={() => setNewCP(true)}>Novo CP</div>
                  </Row>
                </Collapse>
              </div>

              <div className="header-btns-container">
                {cpReport && cpReport.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createXlsxCP(
                        token,
                        100,
                        search,
                        countData,
                        setFinishExport,
                        idEvent
                      ) > setExportInProgress(true)
                    }
                  >
                    Exportar CPs
                  </Button>
                )}

                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setNewCP(true)}
                >
                  Novo CP
                </Button>
              </div>
            </div>

            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Código</th>
                    <th>Quantidade</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {listCp.map((cp, index) => (
                    <tr key={index}>
                      <td>{cp.name}</td>
                      <td>{cp.code}</td>
                      <td>{cp.count}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCpEdit(cp);
                            setIsOpenEditCp(true);
                          }}
                        />
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                            minHeight: "100px",
                          }}
                          onClick={async () => {
                            setCpEdit(cp);
                            setIsConfirmDelete(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>

      <Pagination
        data={listCp}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(QuotasPerCP);
