import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { urlDomain } from "../../../utils/services/config";

export default function ModalLinksEmbeded(props) {
  const { isOpen, toggle, idEvent } = props;

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Links Para Embedar</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <label className="form-label">
                URL Hall
                <i
                  className="mdi mdi-content-copy"
                  style={{
                    color: "grey",
                    fontSize: 17,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  title="Copiar"
                  onClick={() =>
                    clipBoard(
                      `https://lerqrcode.${urlDomain}?idEvent=${idEvent}&idPart={CODIGOINTERNO}`
                    )
                  }
                />
              </label>
              <input
                className="form-control"
                disabled
                value={`https://lerqrcode.${urlDomain}?idEvent=${idEvent}&idPart={CODIGOINTERNO}`}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <label className="form-label">
                URL APP Eventos
                <i
                  className="mdi mdi-content-copy"
                  style={{
                    color: "grey",
                    fontSize: 17,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  title="Copiar"
                  onClick={() =>
                    clipBoard(
                      `https://lerqrcode.${urlDomain}/?idEvent=${idEvent}&idPart={PAXID}`
                    )
                  }
                />
              </label>
              <input
                className="form-control"
                disabled
                value={`https://lerqrcode.${urlDomain}/?idEvent=${idEvent}&idPart={PAXID}`}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
