import axios from "axios";
import { urlBase } from "./config";

export const createDepartment = async (departmentData, bearerToken) => {
  try {
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(
      `${urlBase}/boticario/departments`,
      departmentData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error creating department:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateDepartment = async (id, departmentData, bearerToken) => {
  try {
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
    };

    const response = await axios.put(
      `${urlBase}/boticario/departments/${id}`,
      departmentData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error updating department:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteDepartment = async (id, bearerToken) => {
  try {
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
    };

    const response = await axios.delete(
      `${urlBase}/boticario/departments/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting department:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchDepartments = async (
  token,
  offset,
  limit,
  search,
  columnSearch,
  eventId
) => {
  try {
    const response = await axios.get(
      `${urlBase}/boticario/events/${eventId}/departments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset,
          limit,
          search,
          columnSearch,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar departamentos:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchEstoqueReports = async (token, eventId) => {
  try {
    const response = await axios.get(
      `${urlBase}/boticario/events/${eventId}/stock/report`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar estoque:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchAttendeeReports = async (token, eventId) => {
  try {
    const response = await axios.get(
      `${urlBase}/boticario/events/${eventId}/attendees/report`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar estoque:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchRegistersReports = async (token, eventId) => {
  try {
    const response = await axios.get(
      `${urlBase}/boticario/events/${eventId}/registers/report`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar estoque:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const createCps = async (cpData, bearerToken) => {
  try {
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
    };

    const response = await axios.post(`${urlBase}/boticario/cps`, cpData, { headers });
    return response.data;
  } catch (error) {
    console.error(
      "Erro ao criar CP: ",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const editCp = async (cpData, bearerToken, cpId) => {
  try {
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": "application/json",
    };

    const response = await axios.put(
      `${urlBase}/boticario/cps/${cpId}`,
      cpData,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error Editing CP: ",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchCps = async (
  token,
  offset,
  limit,
  search,
  columnSearch,
  eventId
) => {
  try {
    const response = await axios.get(
      `${urlBase}/boticario/events/${eventId}/cps`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          offset,
          limit,
          search,
          columnSearch,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar CPs: ",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchCpsReports = async (token, eventId) => {
  try {
    const response = await axios.get(
      `${urlBase}/boticario/events/${eventId}/cps/report`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Erro ao buscar CPS REPORT:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteCp = async (id, bearerToken) => {
  try {
    const headers = {
      Authorization: `Bearer ${bearerToken}`,
    };

    const response = await axios.delete(
      `${urlBase}/boticario/cps/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting CP: ",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
