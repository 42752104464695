import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalExcelRowsConfig(props) {
  const { toggle, rows, columnNames } = props;

  const invalidRows = (Array.isArray(rows.data) ? rows.data : []).map(
    (row, rowIndex) => ({
      row: row.index + 2,
      error: Object.entries(columnNames)
        .filter(([_key, value]) => row && row[value] === undefined)
        .map(([key]) => key),
    })
  );

  return (
    <>
      <SweetAlert
        title="Corrija os erros abaixo e reenvie a planilha"
        warning
        confirmButtonText="Ok!"
        confirmBtnBsStyle="success"
        onConfirm={toggle}
        style={{ width: "39.1%" }}
      >
        <div style={{ marginTop: 15 }}>
          {invalidRows.length !== 0 ? (
            <>
              {rows.type === "required" &&
                invalidRows.map((row) => (
                  <p key={row.row}>
                    Linha {row.row}: Preencha os campos: {row.error.join(", ")}{" "}
                    com dados válidos
                  </p>
                ))}

              {rows.type === "duplicate" &&
                invalidRows.map((row) => (
                  <p key={row.row}>Linha {row.row}: Campo Codigo duplicado</p>
                ))}

              {rows.type === "existingCode" &&
                invalidRows.map((row) => (
                  <p key={row.row}>Linha {row.row}: Campo Codigo existente</p>
                ))}

              {rows.type === "text" && <p>{rows.error}</p>}
            </>
          ) : (
            <p>Utilize o Modelo correto para fazer a importação da planilha</p>
          )}
        </div>
      </SweetAlert>
    </>
  );
}
