import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";

import { useSelector } from "react-redux";
import WaitExport from "../../../components/WaitExport";
import { createXlsx } from "./createXlsx";
import { quizProdutosNeogenXlsx } from "./reports/quizProdutosNeogenXlsx";
import { quizProfuseXlsx } from "./reports/quizprofuseXlsx";
import { quizVibraXlsx } from "./reports/quizvibraXlsx";
import { quotasLogsXlsx } from "./reports/logsQuotasXlsx";
import { quotasSelectedsXlsx } from "./reports/selectedsQuotasXlsx";
import { quotasSupporterXlsx } from "./reports/supporterQuotasXlsx";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";
// services
import { getSurveysByGroup } from "../../../utils/services/survey";
import { getAllSurveys } from "../../../utils/services/survey";
import { getQuotasConvention } from "../../../utils/services/quotas";

export default function ReportSurvey(props) {
  const { token: jwt, theme } = useSelector(
    (state) => state.global_user_data.data
  );

  const location = useLocation();
  //search and paginations
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsQuotasLogs, setTotalItemsQuotasLogs] = useState(0);
  const [totalItemsQuotasSelecteds, setTotalItemsQuotasSelecteds] = useState(0);
  const [totalItemsQuotaSsupporter, setTotalItemsQuotaSsupporter] = useState(0);
  // collapse
  const [isOpen, setIsOpen] = useState(false);

  const [surveys, setSurveys] = useState([]);

  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [collapseIsOpenQuiz, setCollapseIsOpenQuiz] = useState(false);
  const [visibleBtnPackLovers, setVisibleBtnPackLovers] = useState(false);
  const [visibleBtnAxe, setVisibleBtnAxe] = useState(false);
  const [btnsAvailable, setBtnsAvailable] = useState([]);
  const [activeFilter, setActiveFilter] = useState("name");

  const columnsFilter = [
    { name: "Participante", value: "name", type: "text" },
    { name: "Pergunta", value: "question", type: "text" },
    { name: "Reposta", value: "answer", type: "text" },
    { name: "Acertou", value: "hit", type: "boolean" },
    { name: "Data", value: "data", type: "date" },
  ];

  const debouncedSearch = useDebounce(search, 300);

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  const formatDate = (date) => {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 8);

    return `${dateFormat}  ${time}`;
  };

  const getAll = useCallback(async () => {
    const response = await getAllSurveys({
      jwt,
      eventId: location.state.idEvent,
      offset: currentPage,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    setSurveys(response?.data?.findAllSurveyData?.data || []);
    setTotalItems(response?.data?.findAllSurveyData?.count);
  }, [jwt, location.state.idEvent, currentPage, itemsPerPage, debouncedSearch]);

  function getBtnsVisible() {
    ["vemDeVibra"].map((item) => {
      checkRegs(item).then((el) => {
        if (el) {
          setBtnsAvailable((prev) => [...prev, item]);
        }
      });
    });
  }

  async function checkRegs(reportName) {
    const res = await getSurveysByGroup({
      jwt,
      eventId: location.state.idEvent,
      offset: 1,
      limit: 10,
      search,
      reportName,
    });
    return res?.data?.findAllSurveyData?.count > 0;
  }

  async function getVisibleButtons() {
    if (
      theme[0]?.id === "8029badb-d64c-4af8-9f52-59b60e346767" &&
      (location.state.idEvent === "88b23836-4a06-4ea1-b6a9-1ba52ce61b9b" ||
        location.state.idEvent === "c1b1e0cf-a169-4b19-b200-479c96fb5cfd")
    ) {
      setVisibleBtnPackLovers(true);

      await getQuotasConvention(jwt, 1, 10, "logs").then((res) =>
        setTotalItemsQuotasLogs(res.count)
      );

      await getQuotasConvention(jwt, 1, 10, "cotasadquiridas").then((res) =>
        setTotalItemsQuotasSelecteds(res.count)
      );

      await getQuotasConvention(jwt, 1, 10, "apoiadorazul").then((res) =>
        setTotalItemsQuotaSsupporter(res.count)
      );
    }

    if (
      theme[0]?.id === "e75977b6-f31b-4813-8732-02651d1febf9" &&
      location.state.idEvent === "0846aa49-7195-4296-867a-eec863269a13"
    ) {
      setVisibleBtnAxe(true);
    }
  }
  useEffect(() => {
    getAll();
    getVisibleButtons();
    getBtnsVisible();
  }, [getAll]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório de Respostas"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Relatórios de Respostas</CardTitle>
          {/* {surveys.length > 0 && visibleBtnPackLovers && ( */}
          {visibleBtnPackLovers && (
            <div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  width: "150px",
                }}
              >
                <SectionComponent
                  sectionName="Relatórios Itaú"
                  setIsOpen={setCollapseIsOpenQuiz}
                  isOpen={collapseIsOpenQuiz}
                />
              </Row>
              <Collapse isOpen={collapseIsOpenQuiz}>
                {/* {btnsAvailable.includes('quiz1') && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quiz1Xlsx({
                        jwt,
                        eventId: location.state.idEvent,
                        limit: 500,
                        search: search,
                        totalItems,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Quiz Geral
                  </Button>
                )} */}
                {/* {btnsAvailable.includes("quiz_produtos_Neogen") && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quizProdutosNeogenXlsx({
                        jwt,
                        eventId: location.state.idEvent,
                        limit: 500,
                        search: search,
                        totalItems,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Quiz Produtos
                  </Button>
                )} */}
                {totalItemsQuotasLogs ? (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quotasLogsXlsx({
                        jwt,
                        offset: 1,
                        limit: 9999,
                        totalItems: totalItemsQuotasLogs,
                        setFinishExport,
                        type: "logs",
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Ações
                  </Button>
                ) : null}

                {totalItemsQuotasSelecteds ? (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quotasSelectedsXlsx({
                        jwt,
                        offset: 1,
                        limit: 9999,
                        totalItems: totalItemsQuotasSelecteds,
                        setFinishExport,
                        type: "cotasadquiridas",
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Cotas Selecionadas
                  </Button>
                ) : null}

                {totalItemsQuotaSsupporter ? (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quotasSupporterXlsx({
                        jwt,
                        offset: 1,
                        limit: 9999,
                        totalItems: totalItemsQuotaSsupporter,
                        setFinishExport,
                        type: "apoiadorazul",
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Cotas Apoiador
                  </Button>
                ) : null}
              </Collapse>
            </div>
          )}
          {visibleBtnAxe && (
            <div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  width: "150px",
                }}
              >
                <SectionComponent
                  sectionName="Relatórios"
                  setIsOpen={setCollapseIsOpenQuiz}
                  isOpen={collapseIsOpenQuiz}
                />
              </Row>
              {/* <Collapse isOpen={collapseIsOpenQuiz}>
                {btnsAvailable.includes("profuse") && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quizProfuseXlsx({
                        jwt,
                        eventId: location.state.idEvent,
                        limit: 500,
                        search: search,
                        totalItems,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Profuse
                  </Button>
                )}
              </Collapse> */}
              <Collapse isOpen={collapseIsOpenQuiz}>
                {btnsAvailable.includes("vemDeVibra") && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light reports-button-add"
                    onClick={() =>
                      quizVibraXlsx({
                        jwt,
                        eventId: location.state.idEvent,
                        limit: 500,
                        search: search,
                        totalItems,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Carbono Zero
                  </Button>
                )}
              </Collapse>
            </div>
          )}
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "data" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={activeFilter === "data" ? "date" : "text"}
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {surveys.length > 0 && (
                  <Row className="mobile-btns-top" style={{ marginTop: 10 }}>
                    <div
                      onClick={() =>
                        createXlsx({
                          jwt,
                          eventId: location.state.idEvent,
                          limit: 500,
                          search: search,
                          totalItems,
                          setFinishExport,
                        }) > setExportInProgress(true)
                      }
                    >
                      Exportar Relatório
                    </div>
                  </Row>
                )}
              </Collapse>
            </div>
            <div className="header-btns-container">
              {surveys.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createXlsx({
                      jwt,
                      eventId: location.state.idEvent,
                      limit: 500,
                      search: search,
                      totalItems,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Relatório
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Participante</th>
                  <th>Pergunta</th>
                  <th>Resposta</th>
                  <th>Acertou</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {surveys.map((e, i) => (
                  <tr>
                    <td>{e.loginName}</td>
                    <td>{e.question}</td>
                    <td>{e.answer}</td>
                    <td>{e.hit ? "Sim" : "Não"}</td>
                    <td>{formatDate(e.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={surveys}
          search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
