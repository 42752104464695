import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../../../components/Buttons/ButtonSpinner";
import { editCp } from "../../../../../utils/services/portalBoticario";
import SweetAlert from "react-bootstrap-sweetalert";

export const ModalEditCP = ({
  isOpen,
  toggle,
  jwt,
  refetch,
  eventId,
  cpEdit,
}) => {
  const [formData, setFormData] = useState({
    name: cpEdit.name,
    code: cpEdit.code,
    count: cpEdit.count,
  });

  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "code" ? value.toUpperCase() : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!formData.name.trim()) validationErrors.name = "Campo obrigatório";
    if (!formData.code.trim()) validationErrors.code = "Campo obrigatório";
    if (!formData.count) validationErrors.count = "Campo obrigatório";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    setIsLoading(true);

    const cpData = {
      name: formData.name,
      code: formData.code,
      count: Number(formData.count),
      eventId,
    };

    try {
      const response = await editCp(cpData, jwt, cpEdit.id);

      if (response) {
        refetch();
        setIsShowSuccess(true);
      }
    } catch (error) {
      const { status, data } = error.response;

      if (data.message === "ID NOT FOUND") {
        setErrors({
          code: "O CP que você está tentando editar não existe ou foi excluído.",
        });
      } else if (data.message === "CODE ALREADY EXISTS") {
        setErrors({
          code: "Este código já está em uso. Escolha outro.",
        });
      } else {
        setErrors({
          api: "Erro ao conectar com o servidor. Tente mais tarde.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} centered autoFocus>
      <ModalHeader toggle={toggle}>Novo CP</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Nome do CP *</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name}</small>
                )}
              </div>
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Código CP *</label>
                <input
                  className="form-control"
                  type="text"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                />
                {errors.code && (
                  <small className="text-danger">{errors.code}</small>
                )}
              </div>
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Quantidade *</label>
                <input
                  className="form-control"
                  type="number"
                  name="count"
                  value={formData.count}
                  onChange={handleChange}
                />
                {errors.count && (
                  <small className="text-danger">{errors.count}</small>
                )}
              </div>
            </Col>

            {errors.api && (
              <small className="text-danger">{errors.api}</small>
            )}
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={toggle}
              disabled={isLoading}
            >
              Fechar
            </Button>
            <ButtonSpinner
              title={"Salvar"}
              type="submit"
              isLoading={isLoading}
            />
          </Row>
        </form>
      </ModalBody>

      {isShowSuccess && (
        <SweetAlert
          title={`Editado`}
          success
          onConfirm={() => {
            setIsShowSuccess(false);
            toggle();
          }}
        >
          CP editado com sucesso!
        </SweetAlert>
      )}
    </Modal>
  );
};
