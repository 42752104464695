import React from "react";

export default function Component(props) {
  return (
    <div className="div-data-icon">
      <i
        onClick={() => {
          props.setIconSelected(props.icon);
          props.toggleBackdrop(props.icon);
        }}
        className={`${props.icon} i-icon`}
        style={{
          backgroundColor: props.iconSelected === props.icon ? "	#59ff59" : "",
        }}
      />
      <a style={{ cursor: "default" }}>{props.icon}</a>
    </div>
  );
}
