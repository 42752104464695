import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Row,
  Button,
  Collapse,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";
import ModalNewStock from "./ModalNewStock";
import ModalEditStock from "./ModalEditStock";
import ModalConfig from "./ModalConfig";
import ModalImportStock from "./ModalImportStock";
import ModalLinksEmbeded from "./ModalLinkEmbeded";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteStockActivity,
  getStockActivities,
} from "../../../utils/services/schedulingAndStock";
import moment from "moment";
import Pagination from "../../../components/Paginationv3";
import SweetAlert from "react-bootstrap-sweetalert";
import { createStockXlsx } from "./createXlsx";
import WaitExport from "../../../components/WaitExport";
import useDebounce from "../../../utils/hooks/useDebounce";

function Stock(props) {
  const location = useLocation();
  const token = props.state.global_user_data.data.token;

  const [search, setSearch] = useState("");
  const [activities, setActivities] = useState([]);
  const [activitiesEdit, setActivitiesEdit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalNewStock, setModalNewStock] = useState(false);
  const [modalEditStock, setModalEditStock] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [modalImportStock, setModalImportStock] = useState(false);
  const [modalLink, setModalLink] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("sala");

  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const debouncedSearch = useDebounce(search, 300);

  // id do evento
  const idEvent = location.state.idEvent;

  const columnsFilter = [
    { name: "Sala", value: "sala", type: "text" },
    { name: "Atividade", value: "atividade", type: "text" },
    { name: "Data e Hora Inicio", value: "dataInicio", type: "date" },
    { name: "Data e Hora Fim", value: "dataFim", type: "date" },
    { name: "Quantidade Máxima", value: "quantidadeMax", type: "text" },
    { name: "Tipo", value: "tipo", type: "text" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  const fetchStockActivities = async () => {
    try {
      const activitiesData = await getStockActivities(
        idEvent,
        token,
        currentIndex > 0 ? currentIndex : 1,
        itemsPerPage,
        treatmentSearch(),
        activeFilter
      );

      setActivities(activitiesData);
      setCountData(activitiesData.total);
    } catch (err) {
      console.error("Erro ao buscar atividades:", err);
      setError("Erro ao buscar atividades.");
    }
  };

  useEffect(() => {
    fetchStockActivities();
  }, [
    idEvent,
    token,
    debouncedSearch,
    currentIndex,
    itemsPerPage,
    activeFilter,
    search,
  ]);

  return (
    <>
      {modalNewStock && (
        <ModalNewStock
          isOpen={modalNewStock}
          toggle={() => setModalNewStock(false)}
          idEvent={idEvent}
          jwt={token}
          refetch={fetchStockActivities}
        />
      )}

      {modalEditStock && (
        <ModalEditStock
          isOpen={modalEditStock}
          toggle={() => setModalEditStock(false)}
          data={activitiesEdit}
          jwt={token}
          refetch={fetchStockActivities}
        />
      )}

      {modalConfig && (
        <ModalConfig
          isOpen={modalConfig}
          toggle={() => setModalConfig(!modalConfig)}
          idEvent={idEvent}
          jwt={token}
        />
      )}

      {modalLink && (
        <ModalLinksEmbeded
          isOpen={modalLink}
          toggle={() => setModalLink(false)}
          eventId={idEvent}
        />
      )}

      {confirmDelete && (
        <SweetAlert
          title="Deseja deletar essa atividade?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await deleteStockActivity(activitiesEdit.id, token);
              await fetchStockActivities();
              setConfirmDelete(false);
              setShowSuccessAlert(true);
            } catch (error) {
              console.error("Erro ao deletar a atividade:", error);
            }
          }}
          onCancel={() => setConfirmDelete(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}

      {showSuccessAlert && (
        <SweetAlert
          title="Deletado"
          success
          onConfirm={() => setShowSuccessAlert(false)}
        >
          Atividade deletada!
        </SweetAlert>
      )}

      {modalImportStock && (
        <ModalImportStock
          isOpen={modalImportStock}
          toggle={() => setModalImportStock(!modalImportStock)}
          idEvent={idEvent}
          jwt={token}
          refetch={fetchStockActivities}
        />
      )}

      <WaitExport
        isOpen={exportInProgress}
        name="Estoque"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <Card>
        <CardBody>
          <CardTitle>Estoque</CardTitle>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <Button
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              style={{ marginRight: 10 }}
              onClick={() => setModalLink(true)}
            >
              Links Para Embedar
            </Button>

            <Button
              color="warning"
              className="btn-rounded waves-effect waves-light events-button-add"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setModalConfig(true)}
            >
              <i
                className="dripicons-gear"
                style={{ color: "white", marginRight: 5 }}
              />
              Configurações
            </Button>
          </div>

          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              width: "50px",
            }}
          >
            <SectionComponent
              sectionName="Filtros"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>
          <Collapse isOpen={collapseIsOpen}>
            <SearchFilters
              columns={columnsFilter}
              activeFilter={activeFilter}
              setActiveFilterHandle={setActiveFilterHandle}
            />
          </Collapse>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type={
                    activeFilter === "dataInicio" || activeFilter === "dataFim"
                      ? "date"
                      : "text"
                  }
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="header-btns-container">
              {activities?.data?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() => {
                    createStockXlsx({
                      eventId: idEvent,
                      token,
                      limit: 100,
                      countData,
                      search,
                      columnSearch: activeFilter,
                      setFinishExport,
                    });
                    setExportInProgress(true);
                  }}
                >
                  Exportar Relatório
                </Button>
              )}

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => setModalImportStock(true)}
              >
                Importar Estoque
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => setModalNewStock(true)}
              >
                Adicionar Estoque
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="thead-light">
                <tr>
                  <th>Sala</th>
                  <th>Atividade</th>
                  <th>Data e Hora Inicio</th>
                  <th>Data e Hora Fim</th>
                  <th>Quantidade Máxima</th>
                  <th>Quantidade Utilizada</th>
                  <th>Tipo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {error ? (
                  <tr>
                    <td colSpan="7">Erro: {error}</td>
                  </tr>
                ) : (
                  activities?.data?.map((activity, index) => (
                    <tr key={index}>
                      <td>{activity.sala}</td>
                      <td>{activity.atividade}</td>
                      <td>
                        {moment
                          .utc(activity.dataHoraInicio)
                          .format("DD/MM/YYYY, HH:mm")
                          .replace(",", "")}
                      </td>
                      <td>
                        {moment
                          .utc(activity.dataHoraFim)
                          .format("DD/MM/YYYY, HH:mm")
                          .replace(",", "")}
                      </td>
                      <td>{activity.quantidadeMax}</td>
                      <td>{activity.quantidadeUtilizada}</td>
                      <td>{activity?.tipo}</td>
                      <td>
                        <i
                          className={"mdi mdi-pencil"}
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setActivitiesEdit(activity);
                            setModalEditStock(true);
                          }}
                        />
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                            minHeight: "100px",
                          }}
                          onClick={async () => {
                            setActivitiesEdit(activity);
                            setConfirmDelete(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Pagination
        data={activities.data}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Stock);
