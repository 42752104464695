//service
import { getConfigurations } from "../../../utils/services/ranking";

export const excelColumnsPtBr = {
  NomeQRCode: "nameQrcode",
  CodigoRanking: "codeRanking",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    nameQrcode: row.nameQrcode ? row.nameQrcode : undefined,
    codeRanking: row.codeRanking ? row.codeRanking : undefined,
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) => row.nameQrcode === undefined || row.codeRanking === undefined
  );

  return rowsWithError;
}

export function findDuplicateName(validateRows) {
  var duplicate = [];

  validateRows.map((row) => {
    var search = validateRows.filter((e) => e.nameQrcode === row.nameQrcode);

    if (search.length > 1) {
      duplicate.push({
        ...row,
        nameQrcode: undefined,
      });
    }
  });

  return duplicate;
}

export async function findInvalidCodes(validateRows, idEvent, token) {
  var allConfig = [];

  await getConfigurations({
    id: idEvent,
    jwt: token,
    offset: 1,
    limit: 1000,
    search: "",
    columnSearch: "code",
  }).then((res) => {
    allConfig = res.data;
  });

  var codesInvalids = [];

  validateRows.map((row) => {
    // console.log(row)

    var search = allConfig.find((e) => e.code === row.codeRanking);

    if (!search) {
      codesInvalids.push(row);
    }
  });

  return codesInvalids;
}
