import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../../components/Buttons/ButtonSpinner";
import { useState } from "react";
import { createDepartment } from "../../../../utils/services/portalBoticario";
import SweetAlert from "react-bootstrap-sweetalert";

export const ModalNewDepartament = (props) => {
  const { isOpen, toggle, jwt, refetch, eventId } = props;

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    availables: "",
    untilAt: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    code: "",
    availables: "",
    untilAt: "",
  });

  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "code") {
      setFormData({
        ...formData,
        [name]: value.toUpperCase().replace(/\s+/g, ""),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "O nome é obrigatório.";
    if (!formData.code.trim()) newErrors.code = "O código é obrigatório.";
    if (!formData.availables || formData.availables <= 0) {
      newErrors.availables = "A quantidade disponível deve ser maior que zero.";
    }
    if (!formData.untilAt.trim()) newErrors.untilAt = "A data é obrigatória.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormatterError = (error) => {
    return (
      {
        "CODE ALREADY EXISTS": "O código informado já existe.",
        "INVALID DATE": "A data fornecida já passou.",
      }[error] ||
      "Ocorreu um problema desconhecido ao criar um novo departamento. Por favor, entre em contato com o suporte técnico."
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    setLoading(true);

    try {
      const departmentData = {
        name: formData.name,
        code: formData.code,
        available: Number(formData.availables),
        untilAt: formData.untilAt,
        eventId,
      };

      await createDepartment(departmentData, jwt);

      refetch();
      setShowSuccessAlert(true);
    } catch (error) {
      setErrorMessage(handleFormatterError(error.response.data.message));
      setShowErrorAlert(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} centered autoFocus>
      <ModalHeader toggle={toggle}>Novo Departamento</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Nome *</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <div style={{ color: "red" }}>{errors.name}</div>
                )}
              </div>
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Código *</label>
                <input
                  className="form-control"
                  type="text"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                />
                {errors.code && (
                  <div style={{ color: "red" }}>{errors.code}</div>
                )}
              </div>
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Quantidade disponível *</label>
                <input
                  className="form-control"
                  min={1}
                  type="number"
                  name="availables"
                  value={formData.availables}
                  onChange={handleChange}
                />

                {errors.availables && (
                  <div style={{ color: "red" }}>{errors.availables}</div>
                )}
              </div>
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Permissão para cadastro *</label>
                <input
                  className="form-control"
                  type="date"
                  name="untilAt"
                  value={formData.untilAt}
                  onChange={handleChange}
                />

                {errors.untilAt && (
                  <div style={{ color: "red" }}>{errors.untilAt}</div>
                )}
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={toggle}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={loading} title={"Salvar"} type="submit" />
          </Row>
        </form>
      </ModalBody>

      {showSuccessAlert && (
        <SweetAlert
          title={`Criado`}
          success
          onConfirm={() => {
            setShowSuccessAlert(false);
            toggle();
          }}
        >
          Departamento criado com sucesso!
        </SweetAlert>
      )}

      {showErrorAlert && (
        <SweetAlert
          title="Erro"
          danger
          onConfirm={() => setShowErrorAlert(false)}
        >
          {errorMessage}
        </SweetAlert>
      )}
    </Modal>
  );
};
