import writeXlsxFile from "write-excel-file";
import { getSurveysByGroup } from "../../../../utils/services/survey";

export async function quizVibraXlsx({
  jwt,
  eventId,
  limit,
  search,
  totalItems,
  setFinishExport,
}) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleString("pt-BR", {
      timeZone: "America/Sao_Paulo",
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return formattedDate.replace(",", " -");
  };

  const schema = [
    {
      column: "Nome",
      type: String,
      value: (e) => e.Nome,
    },
    {
      column: "Email",
      type: String,
      value: (e) => e.Email,
    },
    {
      column:
        "1. Selecione o(s) tipo(s) de transporte que utilizou para se deslocar até o evento.",
      type: String,
      value: (e) =>
        e["Selecione o(s) tipo(s) de transporte que utilizou para se deslo"],
    },
    {
      column:
        "2. Se você veio de carro, qual foi o combustível utilizado? Caso não tenha certeza, basta deixar essa pergunta em branco. Vamos considerar o cenário de maior emissão.",
      type: String,
      value: (e) =>
        e["Se você veio de carro, qual foi o combustível utilizado? Caso"],
    },
    {
      column:
        "3. Realizou uma viagem aérea? No caso, insira origem-destino entre aeroportos.",
      type: String,
      value: (e) =>
        e["Realizou uma viagem aérea? No caso, insira origem-destino entr"],
    },
    {
      column:
        "4. Qual a distância aproximada que percorreu até o evento? (uma estimativa em km da parte terrestre)",
      type: String,
      value: (e) =>
        e["Qual a distância aproximada que percorreu até o evento? (uma "],
    },
    {
      column:
        "5. Se você não tem ideia da distância em km para chegar ao evento de hoje, por favor insira abaixo de qual cidade e nome do bairro do qual você veio.",
      type: String,
      value: (e) =>
        e["Se você não tem ideia da distância em km para chegar ao even"],
    },

    {
      column: "Data e hora da resposta",
      type: String,
      value: (e) => formatDate(e.DataCompleta),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSurveysByGroup({
      jwt,
      eventId,
      offset: i,
      limit,
      search,
      reportName: "vemDeVibra",
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.findAllSurveyData.data)
    .flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "relatorio-CarbonoZero.xlsx",
    });
  }
}
