import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { urlBaseAgendamento, urlDomain } from "../../../utils/services/config";

export default function ModalLinksEmbeded(props) {
  const { isOpen, toggle, eventId } = props;

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }
  //

  return (
    <Modal isOpen={isOpen} autoFocus={true} centered={true}>
      <ModalHeader toggle={toggle}>Links Para Embedar</ModalHeader>
      <ModalBody>
        <Row style={{ marginBottom: 10 }}>
          <Col lg={12}>
            <label className="form-label">
              URL Hall
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    `${urlBaseAgendamento}?idEvent=${eventId}&idPart={CODIGOINTERNO}`
                  )
                }
              />
            </label>
            <input
              className="form-control"
              disabled
              value={`${urlBaseAgendamento}?idEvent=${eventId}&idPart={CODIGOINTERNO}`}
            />
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col lg={12}>
            <label className="form-label">
              URL APP Eventos
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    `${urlBaseAgendamento}/?idEvent=${eventId}&idPart={PAXID}`
                  )
                }
              />
            </label>
            <input
              className="form-control"
              disabled
              value={`${urlBaseAgendamento}/?idEvent=${eventId}&idPart={PAXID}`}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <label className="form-label">
              Relatório Geral
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    `https://agendamentos.${urlDomain}/report?eventId=${eventId}`
                  )
                }
              />
            </label>
            <input
              className="form-control"
              disabled
              value={`https://agendamentos.${urlDomain}/report?eventId=${eventId}`}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <label className="form-label">
              Relatório Por Sala
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    `https://agendamentos.${urlDomain}/reportroom/?eventId=${eventId}&room={Inserir Nome Da Sala}`
                  )
                }
              />
            </label>
            <input
              className="form-control"
              disabled
              value={`https://agendamentos.${urlDomain}/reportroom/?eventId=${eventId}&room={Inserir Nome Da Sala}`}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
