import writeXlsxFile from "write-excel-file";
import { fetchDepartments } from "../../../../utils/services/portalBoticario";
import { formatData } from "../../../../utils/helpers/files/formatDate";

export async function createXlsxDepartaments(
  jwt,
  limit,
  search,
  countData,
  setFinishExport,
  idEvent
) {
  const formatColumn = (col) => {
    return col ? formatData(col) : "";
  };

  const schema = [
    {
      column: "Nome",
      type: String,
      value: (participant) => participant.name,
    },
    {
      column: "Código",
      type: String,
      value: (participant) => participant.code,
    },
    {
      column: "Quantidade Disponível",
      type: Number,
      value: (participant) => participant.available,
    },
    {
      column: "Data Limite",
      type: String,
      value: (participant) => formatColumn(participant.untilat),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await fetchDepartments(jwt, i, limit, search, "", idEvent);
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "departamentos.xlsx",
    });
  }
}
