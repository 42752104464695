import axios from "axios";
import { urlBase } from "./config";
import axiosInstance from "./config/axiosinstance";

export async function createStockActivity(
  eventId,
  dataHoraInicio,
  dataHoraFim,
  quantidadeMax,
  quantidadeUtilizada,
  atividade,
  sala,
  tipo,
  token
) {
  try {
    const response = await axios.post(
      `${urlBase}/estoqueatividades`,
      {
        eventId,
        dataHoraInicio,
        dataHoraFim,
        quantidadeMax,
        quantidadeUtilizada,
        atividade,
        tipo,
        sala,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao enviar atividade de estoque:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao criar a atividade de estoque."
    );
  }
}

export async function updateStockActivity(id, data, token) {
  try {
    const response = await axios.put(
      `${urlBase}/estoqueatividades/${id}`,
      {
        sala: data.room,
        atividade: data.activity,
        dataHoraInicio: data.initialDate,
        dataHoraFim: data.endDate,
        quantidadeMax: Number(data.maxQuantity),
        tipo: data.tipo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar a atividade de estoque:", error);
    throw new Error(
      error.response?.data?.message ||
        "Erro ao atualizar a atividade de estoque."
    );
  }
}

export async function deleteStockActivity(id, token) {
  try {
    const response = await axios.delete(`${urlBase}/estoqueatividades/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao deletar a atividade:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao deletar a atividade."
    );
  }
}

export async function getStockActivities(
  eventId,
  token,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  try {
    const response = await axios.get(
      `${urlBase}/estoqueatividades/filters/event/${eventId}?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar atividades de estoque:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao obter atividades de estoque."
    );
  }
}

export async function getAllStockActivities({
  eventId,
  token,
  offset,
  limit,
  search,
  columnSearch,
}) {
  try {
    const response = await axios.get(
      `${urlBase}/estoqueatividades/filters/event/${eventId}?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar atividades de estoque:", error);
    throw new Error(
      error.response?.data?.message || "Erro ao obter atividades de estoque."
    );
  }
}

export const fetchConfig = async (idEvent, jwt) => {
  const url = `${urlBase}/agendamentoconfig/${idEvent}/event?offset=1&limit=10`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erro ao buscar config:", error);
    throw error;
  }
};

export const saveConfig = async (idConfig, jwt, payload, isEditing) => {
  const method = isEditing ? "PUT" : "POST";
  const url = isEditing
    ? `${urlBase}/agendamentoconfig/${idConfig}`
    : `${urlBase}/agendamentoconfig`;

  try {
    const response = await axios({
      method: method,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      data: payload,
    });

    return response;
  } catch (error) {
    console.error("Erro ao salvar config:", error);
    throw error;
  }
};

export function importStock(data) {
  var dataForm = JSON.stringify(data);
  var config = {
    method: "post",
    url: `${urlBase}/estoqueatividades/import`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
