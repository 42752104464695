import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
// componentes
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
// select
import Select from "react-select";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalConfig(props) {
  const { isOpen, toggle } = props;
  // cor
  const [colorBg, setColorBg] = useState("#ffffff");
  const [colorBtn, setColorBtn] = useState("#ffffff");
  // img
  const [imageBackground, setImageBackground] = useState("");
  const [imageBackgroundView, setImageBackgroundView] = useState();
  // limitar por data
  const [dataLimit, setDataLimit] = useState(false);
  // carregamento
  const [loadingBtn, setLoadingBtn] = useState(false);
  // erros
  const [errors, setErrors] = useState(false);
  // alerta
  const [success, setSuccess] = useState(false);

  function verifyForm(e) {
    if (!imageBackground) {
      setErrors({
        image: "O campo Imagem é obrigatório",
      });
      return;
    } else setErrors({ image: "" });

    if (dataLimit && !e.target[4].value) {
      setErrors({
        dateStart: "O campo Data e Hora Início é obrigatório",
      });
      return;
    } else setErrors({ dateStart: "" });

    if (dataLimit && !e.target[5].value) {
      setErrors({
        dateEnd: "O campo Data e Hora Fim é obrigatório",
      });
      return;
    } else setErrors({ dateEnd: "" });

    if (dataLimit && !e.target[6].value) {
      setErrors({
        textBtn: "O campo Texto para Substituir o Botão é obrigatório",
      });
      return;
    } else setErrors({ textBtn: "" });

    setLoadingBtn(true);

    setTimeout(() => {
      setLoadingBtn(false);
      setSuccess(true);
    }, 2000);
  }

  return (
    <>
      {success && (
        <SweetAlert
          success
          title={"Concluído"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Configurações salvas com sucesso!"}
        </SweetAlert>
      )}
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Configurações</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyForm(e);
            }}
          >
            {" "}
            <Row>
              <Col md={12}>
                <ColorPicker
                  classOfContainer="primary-card-pickr"
                  labelText="Cor de Fundo"
                  setStateFunc={setColorBg}
                  defaultColor={colorBg}
                  idDiv="bgColor"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <ColorPicker
                  classOfContainer="secundary-card-pickr"
                  labelText="Cor do Botão"
                  setStateFunc={setColorBtn}
                  defaultColor={colorBtn}
                  idDiv="btnColor"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={imageBackgroundView && !imageBackground ? 6 : 12}>
                <Label htmlFor="formFile" className="form-label">
                  Imagem *
                </Label>
                <Input
                  className="form-control"
                  type="file"
                  id="formFile"
                  defaultValue={null}
                  onChange={(e) => setImageBackground(e)}
                />
                {errors && errors.image && (
                  <a style={{ color: "red" }}>{errors.image}</a>
                )}
              </Col>

              {imageBackgroundView && !imageBackground && (
                <Col md={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={imageBackgroundView}
                      style={{ width: 100, height: 100 }}
                    />
                    <i
                      className="mdi mdi-trash-can icon-trash"
                      onClick={() => {
                        setImageBackgroundView(null);
                        setImageBackground(null);
                      }}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Limitar QR Code por Data?</label>
                <Select
                  placeholder={"Selecione"}
                  defaultValue={{ label: "Não", value: "Não" }}
                  onChange={(e) => {
                    if (e.value === "Sim") {
                      setDataLimit(true);
                    } else setDataLimit(false);
                  }}
                  options={[
                    {
                      options: [
                        { label: "Não", value: "Não" },
                        { label: "Sim", value: "Sim" },
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </Col>
            </Row>
            {dataLimit && (
              <>
                <Row style={{ marginTop: 10 }}>
                  <Col md={6}>
                    <label className="form-label">Data e Hora Início *</label>
                    <input className="form-control" type="datetime-local" />
                    {errors && errors.dateStart && (
                      <a style={{ color: "red" }}>{errors.dateStart}</a>
                    )}
                  </Col>

                  <Col md={6}>
                    <label className="form-label">Data e Hora Fim *</label>
                    <input className="form-control" type="datetime-local" />
                    {errors && errors.dateEnd && (
                      <a style={{ color: "red" }}>{errors.dateEnd}</a>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Texto para Substituir o Botão *
                    </label>
                    <input className="form-control" type="text" />
                    {errors && errors.textBtn && (
                      <a style={{ color: "red" }}>{errors.textBtn}</a>
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Row
              style={{
                width: "100%",
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>

              <ButtonSpinner isLoading={loadingBtn} title={"Salvar"} />
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
